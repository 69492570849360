body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    /* "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom scroll bar start */
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #bf9422 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#f1c03a),
    to(#bf9422),
    color-stop(0.6, #f3b50b)
  );
}
/* custom scroll bar end */

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #bf9422 #fff;
}

*::-moz-scrollbar {
  width: 5px;
}

*::-moz-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: linear-gradient(#f1c03a, #bf9422 75%, #f3b50b 60%);
}

*::-moz-scrollbar-track {
  background: #ffffff;
}

body {
  cursor: default;
}
